<template>
    <div class="page-loader">
        <svg
            class="simple-page-loader"
            width="78"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#596778"
        >
            <g transform="translate(1 1)" stroke-width="1" fill="none" fill-rule="evenodd">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"
                    />
                </path>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'PageLoader',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-loader {
    min-height: 100vh;
}
.simple-page-loader {
    position: fixed;
    top: 25vh;
    left: 50%;
    transform: translate(-50%, 0);

    margin-top: 0;

    opacity: 0.35;

    z-index: 99;
}
</style>
